import gql from 'graphql-tag';
/**
 * @allBranchQuery @query - HQ Dashboard
 * @description: TAB: All branches
 */

export const hqceDashboardBranchExportList = /* GraphQL */ gql`
    query dashboardExportList($input: DashboardExportInput) {
        dashboardExportList(input: $input) {
            data {
                result {
                    hqcedashboardBranch {
                        name
                        code
                        state
                        dailyCount
                        rerouteCount
                        hardcopyCount
                    }
                }
            }
            error {
                errorCode
                message
                statusCode
                errorList
            }
        }
    }
`;
